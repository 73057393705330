import React from "react";
import "../styles/containers/cellphone.css";
import SecondBackground from "../assets/images/secondBackground.png";
import SecondPhone from "../assets/images/secondPhone.png";

const Cellphone = () => {
  return (
    <div className="cellphone">
      <img
        src={SecondBackground}
        alt="second-background"
        className="cellphone_background"
      />
      <img
        src={SecondPhone}
        alt="cellphone-image"
        className="cellphone_second-phone"
      />
      <div className="cellphone_text-container">
        <h2 className="cellphone_text-title">No solo una red social</h2>
        <ul className="cellphone_text-list">
          <li className="cellphone_text-list-item">
            Hoshii busca recuperar los vínculos de tú a tú que tenía la internet
            primitiva, donde las personas se relacionaban de forma más directa y
            auténtica, sin intermediarios ni influencias externas.
          </li>
          <li className="cellphone_text-list-item">
            Hoshii se basa en los gustos y la creación de contenido de sus
            usuarios, lo que permite encontrar conexiones profundas y duraderas
            con personas que comparten intereses, pasiones y visiones similares.
          </li>
          <li className="cellphone_text-list-item">
            Hoshii fomenta la comunidad, al ofrecer herramientas para la
            comunicación, la colaboración y el aprendizaje mutuo entre sus
            miembros, que pueden escalar sus vínculos y convertirlos en algo más
            grande.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Cellphone;
