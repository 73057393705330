import React from "react";
import "../styles/containers/banner.css";
import mainBanner from "../assets/images/mainBanner.png";
import mainPhone from "../assets/images/mainPhone.svg";

const Banner = () => {
  return (
    <div className="banner">
      <img src={mainBanner} alt="banner_image" className="banner_image" />
      <div className="banner_content_container">
        <div className="banner_content-text-container">
          <h1 className="banner_content-text">
            Conoce personas interesantes, crea experiencias de valor y unete a
            un sin fin de comunidades hechas a tu medida.
          </h1>
          <a href="https://app.hoshii.co/login">
            <button className="banner_content-button">Comenzar ahora!</button>
          </a>
        </div>
        <img
          src={mainPhone}
          alt="phone_image"
          className="banner_content-image"
        />
      </div>
    </div>
  );
};

export default Banner;
