import React from 'react'
import "../styles/containers/appSection.css"
import AppIcons from "../assets/images/appsIcons.png"

const AppSection = () => {
  return (
    <div className="app-section">
        <div className="app-section_main-container">
          <div className="app-section_main-container_first-bubble"></div>
          <div className="app-section_main-container_second-bubble"></div>
          <p className="app-section_main-text">Proximamente...</p>
          <img src={AppIcons} alt="apps-icons" className="app-section_main-app-icons" />
        </div>
    </div>
  )
}

export default AppSection