import "./App.css";
import Divider from "./components/Divider";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Banner from "./containers/Banner";
import Cards from "./containers/Cards";
import Cellphone from "./containers/Cellphone";

import MainWave from "./assets/images/mainWave.svg";
import SecondaryWave from "./assets/images/secondaryWave.svg";
import { useEffect, useState } from "react";
import HiddenHeader from "./components/HiddenHeader";
import AppSection from "./containers/AppSection";

function App() {
  const [toggleHeader, setToggleHeader] = useState(true);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      // Comprobar si la ventana está en la parte más alta
      if (scrollTop === 0) {
        // Quitar la clase hidden al header
        setToggleHeader(true)
      } else {
        // Añadir la clase hidden al header
        setToggleHeader(false)
      }
    });
  }, []);

  return (
    <>
      <Header toggleHeader={toggleHeader}/>
      <HiddenHeader toggleHeader={toggleHeader}/>
      <Banner />
      <Divider />
      <Cards />
      <Divider />
      <Cellphone />
      <AppSection />
      <Footer />
      <img src={MainWave} alt="main-waves" className="app_waves-main-layer" />
      <img
        src={SecondaryWave}
        alt="secondary-waves"
        className="app_waves-secondary-layer"
      />
    </>
  );
}

export default App;
