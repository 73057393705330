import React from "react";
import "../styles/components/hiddenHeader.css";
import ColorLogo from "../assets/icons/colorLogo.svg";

const HiddenHeader = ({ toggleHeader }) => {
  return (
    <div className={`hidden-header ${toggleHeader && "hidden-header_hidden"}`}>
      {/* <div className="hidden-header_logo-container">
        <img src={ColorLogo} alt="hoshii-logo" className="header_logo" />
      </div> */}
      <img src={ColorLogo} alt="hoshii-logo" className="hidden-header_logo" />
      <a href="https://app.hoshii.co/login">
      <button className="hidden-header_button">Unirme a Hoshii</button>
      </a>
    </div>
  );
};

export default HiddenHeader;
