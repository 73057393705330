import React from 'react'
import "../styles/components/header.css"
import Logo from "../assets/icons/logo.svg"

const Header = ({toggleHeader}) => {
  return (
    <div className={`header ${!toggleHeader && "header-hidden"}`}>
      <img src={Logo} alt="hoshii-logo" className="header_logo" />
    </div>
  )
}

export default Header