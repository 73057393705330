import React from "react";
import "../styles/containers/cards.css";
//icons
import Net from "../assets/icons/net.svg"
import User from "../assets/icons/user.svg"
import Idea from "../assets/icons/idea.svg"

const Cards = () => {
  return (
    <div className="cards">
      <div className="cards_card">
        <img src={Net} alt="icon" className="cards_card-image" />
        <p className="cards_card-text">
          Hoshii es más que una red social, es una comunidad de personas que se
          apoyan, se inspiran y se divierten juntas. Descubre lo que Hoshii
          puede hacer por ti.
        </p>
      </div>
      <div className="cards_card">
        <img src={User} alt="icon" className="cards_card-image" />
        <p className="cards_card-text">
          Con Hoshii, no solo sigues a personas, sino que las conoces de verdad.
          Encuentra a tu alma gemela, a tu mejor amigo o a tu compañero de
          aventuras.
        </p>
      </div>
      <div className="cards_card">
        <img src={Idea} alt="icon" className="cards_card-image" />
        <p className="cards_card-text">
          Hoshii es la red social que te devuelve la magia de la internet
          primitiva, donde podías relacionarte de tú a tú con personas de todo
          el mundo. Únete a Hoshii y vive experiencias únicas.
        </p>
      </div>
    </div>
  );
};

export default Cards;
